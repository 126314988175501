var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-body',[_c('table',{staticClass:"table table-bordered table-hover"},[_c('tr',[_c('td',[_vm._v("Type")]),_c('td',[_vm._v("Review")]),_c('td',[_vm._v("Review Image")]),_c('td',[_vm._v("Academic Year ")]),_c('td',[_vm._v("Action ")])]),_vm._l((_vm.student_disciplinary_records),function(val,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(val.type))]),_c('td',[_vm._v(_vm._s(val.review)+" ")]),_c('td',[(val.review_file)?[_c('b-button',{attrs:{"variant":"primary"}},[_c('a',{attrs:{"href":_vm.imgShow(val.review_file),"target":"_blank"}},[_c('feather-icon',{staticClass:"text-body ",attrs:{"icon":"EyeIcon","size":"15"}})],1)])]:_vm._e()],2),_c('td',[_vm._v(_vm._s(_vm.academic_yearByID(val.academic_year_id))+" ")]),_c('td',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.selectRow(val)}}},[_c('feather-icon',{staticClass:"text-body ",attrs:{"icon":"EditIcon","size":"15"}})],1)],1)])})],2),_c('h4',{staticClass:"mt-5"},[_vm._v("Add New/Update")]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{ref:"ed_q_form"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Type'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"type","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('type')},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Review Image'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('review image'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"name":"review_file","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('select an image(jpg,png,jpeg)')},model:{value:(_vm.review_file),callback:function ($$v) {_vm.review_file=$$v},expression:"review_file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Review'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('review'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"name":"review","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('review')},model:{value:(_vm.review),callback:function ($$v) {_vm.review=$$v},expression:"review"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Academic Year'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('academic year'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.academic_year_id),expression:"academic_year_id"}],staticClass:"form-control",attrs:{"name":"academic_year_id","state":errors.length > 0 ? false:null},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.academic_year_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("Select One")]),_vm._l((_vm.academic_years),function(ac,key){return _c('option',{key:key,domProps:{"value":ac.id}},[_vm._v(_vm._s(ac.year))])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-2"},[_c('b-button',{attrs:{"variant":"danger","type":"button"},on:{"click":function($event){return _vm.clear()}}},[_vm._v(" Clear ")]),_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }