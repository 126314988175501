<template>
  <b-card-body>
    <table class="table table-bordered table-hover">
      <tr>
        <td>Type</td>
        <td>Review</td>
        <td>Review Image</td>
        <td>Academic Year </td>
        <td>Action </td>
      </tr>
      <tr v-for="(val,key) in student_disciplinary_records" :key="key">
        <td>{{ val.type}}</td>
        <td>{{ val.review }} </td>
        <td>
          <template v-if="val.review_file">
            <b-button variant="primary">
              <a :href="imgShow(val.review_file)" target="_blank">
                <feather-icon class="text-body " icon="EyeIcon" size="15" />
              </a>
            </b-button>
          </template>
        </td>
        <td>{{ academic_yearByID(val.academic_year_id)  }} </td>
        <td>
          <b-button  @click="selectRow(val)"
                     variant="primary">
            <feather-icon class="text-body " icon="EditIcon" size="15" />
          </b-button>
        </td>
      </tr>
    </table>

    <h4 class="mt-5">Add New/Update</h4>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form ref="ed_q_form">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Type') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('type')"
                  rules="required"
              >
                <b-form-input
                    v-model="type"
                    name="type"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('type')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Review Image') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('review image')"
                  rules=""
              >
                <b-form-file
                    v-model="review_file"
                    name="review_file"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('select an image(jpg,png,jpeg)')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Review') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('review')"
                  rules="required"
              >
                <b-form-textarea
                    v-model="review"
                    name="review"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('review')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Academic Year') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('academic year')"
                  rules="required"
              >
                <select class="form-control"
                    v-model="academic_year_id"
                    name="academic_year_id"
                    :state="errors.length > 0 ? false:null"
                >
                  <option :value="null">Select One</option>
                  <option v-for="(ac, key) in academic_years" :key="key" :value="ac.id">{{ac.year}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col class="mt-2">
            <b-button
                variant="danger"
                type="button" @click="clear()"
            >
              Clear
            </b-button>
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-body>
</template>
<script>
import {
  BCardBody,BButton, BFormGroup, BFormInput,
  BForm,BRow, BCol,BCardText,BFormTextarea,BFormFile
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {required} from '@validations'
import apiCall from "@/libs/axios";
import {mapGetters} from "vuex";
export default {
  name:'StudentDisciplinaryRecord',
  components:{
    BCardBody,ValidationProvider, ValidationObserver,
    BButton, BFormGroup, BFormInput,
    BForm,BRow, BCol,BCardText,BFormTextarea,BFormFile,
  },
  props:{
    student:{
      type:Object,
      required:true,
    }
  },
  data(){
    return{
      model_mode:'add',
      required,
      selected_row:{},
      type:'',
      review:'',
      review_file:null,
      academic_year_id:null,
    }
  },
  methods:{
    imgShow(path) {
      return process.env.VUE_APP_ENV_RESOURCHURL + path;
    },
    clear(){
      this.model_mode='add';
      this.selected_row={};
      this.type='';
      this.review='';
      this.review_file=null;
      this.academic_year_id=null;
    },
    selectRow(row){
      this.selected_row=row;
      this.type=row.type;
      this.review=row.review;
      this.review_file=null;
      this.academic_year_id=row.academic_year_id;
      this.model_mode='edit';
    },
    validationForm(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.ed_q_form);
          data.append('student_id',this.student.id);
          if (this.model_mode ==='add'){
            apiCall.post(`/student/disciplinary/record/store`,data).then((response)=>{
              if (response.data.status==='success'){
                this.clear();
                this.$refs.simpleRules.reset();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_STUDENT_DISCIPLINARY_RECORD');
              }else this.$toaster.error(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/student/disciplinary/record/update${this.selected_row.id}`,data).then((response)=>{
              if (response.data.status==='success'){
                this.selected_row={};
                this.clear();
                this.$refs.simpleRules.reset();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_STUDENT_DISCIPLINARY_RECORD');
              }else this.$toaster.error(response.data.message);

            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    }
  },
  computed: {
    student_disciplinary_records(){
      return this.$store.getters.student_disciplinary_records.filter(item=>parseInt(item.student_id)===parseInt(this.student.id));
    },
    ...mapGetters([
      'academic_years', 'academic_yearByID'
    ]),

  },
}
</script>